import Decimal from '@st/decimal'
import { useCalypso } from '../../../composables/useCalypso'

interface PaymentMethod {
  minLimit?: number
  maxLimit?: number
  fixedRate: number
}

interface UseLimitsParams {
  selectedMethod: MaybeRef<PaymentMethod | undefined>
  currencyId: MaybeRef<number>
}

export function useLimits({ selectedMethod, currencyId }: UseLimitsParams) {
  return computed(() => {
    const { getLimitsByCurrencyId } = useCalypso()
    const { min, max } = getLimitsByCurrencyId(unref(currencyId))

    const selectedMethodValue = toValue(selectedMethod)

    if (!selectedMethodValue) {
      return {
        minLimit: min,
        maxLimit: max,
      }
    }

    const rate = selectedMethodValue.fixedRate

    const { minLimit, maxLimit } = selectedMethodValue

    const convertedMinLimit = minLimit
      ? new Decimal(minLimit).div(rate).toDecimalPlaces(2).toString()
      : min
    const convertedMaxLimit = maxLimit
      ? new Decimal(maxLimit).div(rate).toDecimalPlaces(2).toString()
      : max

    const foundMinLimit = new Decimal(min).greaterThan(convertedMinLimit)
      ? min
      : convertedMinLimit
    const foundMaxLimit = new Decimal(max).greaterThan(convertedMaxLimit)
      ? convertedMaxLimit
      : max

    return {
      minLimit: foundMinLimit,
      maxLimit: foundMaxLimit,
    }
  })
}
