<template>
  <div class="hints" :class="platform">
    <div class="hint">
      <div class="icon">
        <StIcon
          name="circle-info-solid"
          :size="platform === 'desktop' ? '20' : '16'"
        />
      </div>
      <div class="text">
        <i18n-t keypath="payments.deposit.hintAddress">
          <template #currency>
            <span data-t="selected-currency">
              {{ selectedCurrency.code }}
            </span>
          </template>
          <template #network>
            <span data-t="selected-network">
              {{ selectedNetwork?.description }}
            </span>
          </template>
        </i18n-t>
      </div>
    </div>
    <div v-if="minimalDeposit" class="hint">
      <div class="icon">
        <StIcon
          :name="selectedCurrency.icon"
          :size="platform === 'desktop' ? '17.5' : '13.5'"
        />
      </div>
      <div class="text" data-t="minimal-deposit">
        <i18n-t keypath="payments.deposit.minimalDeposit">
          <template #currency>
            <span>
              {{ selectedCurrency.code }}
            </span>
          </template>
          <template #amount>
            <span>
              {{ minimalDeposit }}
            </span>
          </template>
        </i18n-t>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Currency, Network } from '../../../interfaces'
import { useTariffsStore } from '../../../stores/useTariffsStore'

const { platform } = usePlatform()

const { findLimit } = useTariffsStore()
const { format } = useCryptoFormatter()

const props = defineProps<{
  selectedCurrency: Currency
  selectedNetwork: Network
}>()

const minimalDeposit = computed(() => {
  if (!props.selectedNetwork?.id) return ''

  const foundLimit = findLimit({
    currencyId: props.selectedCurrency.id,
    networkId: props.selectedNetwork?.id,
    operationType: 'deposit',
  })

  if (!foundLimit || !Number(foundLimit.minAmount)) return ''

  return format(foundLimit.minAmount)
})
</script>

<style scoped>
.hint {
  display: flex;
  gap: var(--spacing-100);
  align-items: flex-start;

  margin-top: var(--spacing-200);

  font: var(--desktop-text-sm-medium);
  color: var(--palette-light-600);

  .text {
    span {
      color: var(--palette-light-1000);
    }
  }

  .icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    line-height: 0;
  }
}

.hints.mobile {
  .hint {
    margin-top: var(--spacing-100);
    font: var(--mobile-caption-1-regular);
  }
}
</style>
