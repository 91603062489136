<template>
  <div class="ton-connect" :class="platform" data-t="ton-connect">
    <div v-if="platform === 'desktop'" class="go-back">
      <StButton
        :label="t('payments.deposit.goBack')"
        size="l"
        type="text-only"
        icon="chevron-left"
        is-left-icon
        @click="emit('back')"
      />
    </div>
    <div class="content">
      <h2 v-if="platform === 'desktop'">
        {{ t('payments.tonConnect.depositFromWallet') }}
      </h2>
      <div class="wallet">
        <img
          src="./assets/wallet.png"
          alt="wallet"
          :width="platform === 'desktop' ? '40' : '32'"
          :height="platform === 'desktop' ? '40' : '32'"
        />
        <div class="address">
          <div class="label">{{ t('payments.tonConnect.wallet') }}</div>
          <div clas="value">
            {{ truncatedAddress }}
          </div>
        </div>
        <StButton
          :label="t('payments.tonConnect.disable')"
          :size="platform === 'desktop' ? 'l' : 'm'"
          type="gray"
          @click="disconnectWallet"
        />
      </div>
      <StInput
        v-model="amount"
        :label="t('payments.tonConnect.sum')"
        :size="platform === 'desktop' ? 'l' : 'm'"
      />
      <div class="button-wrapper">
        <StButton
          :label="t('payments.tonConnect.deposit')"
          @click="handleButtonClicked"
        />
        <div class="disclaimer">
          {{ t('payments.tonConnect.disclaimer', { address: depositAddress }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTonConnect } from '../../composables/useTonConnect'

const props = defineProps<{
  depositAddress: string
}>()

const emit = defineEmits<{
  back: []
}>()

const { t } = useI18n()
const { platform } = usePlatform()
const { walletAddress, sendTransaction, disconnect } = useTonConnect()

const truncatedAddress = computed(() => {
  if (!walletAddress.value) return ''

  if (walletAddress.value.length <= 14) {
    return walletAddress.value
  }

  const leftLength = 5
  const rightLength = 5

  const leftSide = walletAddress.value.slice(0, leftLength)
  const rightSide = walletAddress.value.slice(-1 * rightLength)

  return `${leftSide}...${rightSide}`
})

const amount = ref()

function handleButtonClicked() {
  const transaction = {
    validUntil: Math.floor(Date.now() / 1000) + 180,
    messages: [
      {
        address: props.depositAddress,
        amount: amount.value,
      },
    ],
  }

  sendTransaction(transaction)
}

async function disconnectWallet() {
  await disconnect()
  emit('back')
}
</script>

<style scoped>
.ton-connect {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-300);
}

h2 {
  margin: 0;
  padding: 0;
}

.wallet {
  display: flex;
  gap: var(--spacing-200, 16px);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: var(--spacing-150, 12px) var(--spacing-200, 16px);

  background: var(--background-primary);
  border-radius: var(--border-radius-200);
  box-shadow: 0 0 80px -8px #1d1d2a;

  .address {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .label {
    font: var(--desktop-text-sm-medium);
    color: var(--text-tertiary);
  }

  .value {
    font: var(--desktop-text-md-medium);
    color: var(--text-primary);
  }

  img {
    border-radius: var(--border-radius-150);
  }
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-150);
  justify-content: flex-end;

  width: 100%;
  height: 100%;
}

.disclaimer {
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
  text-align: center;
}

.ton-connect.mobile {
  .content {
    padding: 0 var(--spacing-200);
  }

  .wallet {
    padding: var(--spacing-100);
  }

  .disclaimer {
    padding-bottom: var(--spacing-200);
    font: var(--mobile-caption-1-regular);
  }
}
</style>
