import { minBy } from '@st/utils'
import { useCurrenciesStore } from '../../../stores/useCurrenciesStore'
import { useAccountsStore } from '../../../stores/useAccountsStore'
import { useCalypso as useCalypsoInit } from './useCalypso'

interface PaymentMethod {
  methodName: string
  methodCode: string
  fixedRateId: string
  fixedRate: number
  expiredFixedRateDate: string
}

export const useDataStore = defineStore('dataStore', () => {
  const CURRENCY_ID = 1
  const NETWORK_ID = 4
  const currenciesStore = useCurrenciesStore()
  const accountsStore = useAccountsStore()
  const { platform } = usePlatform()
  const { realAccounts } = storeToRefs(accountsStore)
  const { appCurrency } = storeToRefs(currenciesStore)

  const { allowedCurrencyIds } = useCalypso()
  const currencyId = ref(CURRENCY_ID)
  const networkId = ref(NETWORK_ID)

  const fiatCurrencyId = ref()

  const { exchangeMethods, fiatCurrenciesList } = useCalypsoInit({
    currencyId,
    networkId,
    fiatCurrencyId,
  })

  watch(fiatCurrenciesList, (newValue) => {
    if (!newValue.length) return

    fiatCurrencyId.value = newValue[0]?.id ?? ''
  })

  const selectedMethod = ref<PaymentMethod>()
  watch(exchangeMethods, (newValue) => {
    if (!newValue) return

    const { 0: firstElement } = newValue
    selectedMethod.value = firstElement
  })

  function setSelectedMethod(method: PaymentMethod) {
    selectedMethod.value = method
  }

  const { format: formatCurrency } = useCurrencyFormatter({
    currency: appCurrency.value.code,
  })

  const selectedCurrency = computed(() =>
    currenciesStore.getCurrencyById(CURRENCY_ID),
  )

  const selectedFiatCurrency = computed(() =>
    fiatCurrenciesList.value.find(
      (currency) => currency.id === fiatCurrencyId.value,
    ),
  )

  const { format: formatCrypto } = useCryptoFormatter({
    currency: computed(() => selectedCurrency.value.code),
  })

  const cryptoCurrenciesList = computed(() =>
    realAccounts.value
      .filter((account) =>
        allowedCurrencyIds.value.includes(account.currencyId),
      )
      .map((account) => ({
        id: account.currencyId,
        title: account.code,
        value:
          platform.value === 'mobile'
            ? formatCrypto(account.balance)
            : undefined,
        subValue:
          platform.value === 'mobile'
            ? `~${formatCurrency(account.fiatBalance)}`
            : undefined,
        icon: account.icon,
      })),
  )

  const convertRate = computed(() => selectedMethod.value?.fixedRate || 1)
  const minimalConvertRate = computed(() => {
    const foundMethod = minBy(
      exchangeMethods.value,
      (method) => method.fixedRate,
    )

    return foundMethod?.fixedRate
  })

  return {
    fiatCurrenciesList,
    fiatCurrencyId,
    exchangeMethods,
    selectedMethod,
    cryptoCurrenciesList,
    currencyId,
    networkId,
    selectedCurrency,
    convertRate,
    minimalConvertRate,
    selectedFiatCurrency,
    setSelectedMethod,
  }
})
