<template>
  <div class="deposit" :class="platform" data-t="deposit">
    <NavigationTabs v-if="current === 'methods'" :type="platform" />
    <DepositMethods
      v-if="current === 'methods'"
      :type="platform"
      @handle-currency-selected="handleCurrencySelected"
    />
    <DepositForm
      v-if="current === 'form'"
      :type="platform"
      :selected-currency-id="selectedCurrencyId"
      @back="goToPrevious"
      @handle-connect="handleTonConnect"
    />
    <ClientOnly v-if="isTonConnectEnabled">
      <TonConnect
        v-if="current === 'tonConnect'"
        :deposit-address="depositAddress"
        @back="goTo('form')"
      />
      <StDrawer v-model="isTonConnectDrawerOpened" :full-screen="false">
        <StDrawerHeader
          :title="t('payments.tonConnect.depositFromWallet')"
          @close="isTonConnectDrawerOpened = false"
        />
        <TonConnect :deposit-address="depositAddress" />
      </StDrawer>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useTonConnectEnabled } from '../../composables/useTonConnectEnabled'

const props = withDefaults(defineProps<{ platform?: 'desktop' | 'mobile' }>(), {
  platform: 'desktop',
})

const { t } = useI18n()

const isTonConnectEnabled = useTonConnectEnabled()

const { goTo, current, goToPrevious } = useStepper([
  'methods',
  'form',
  'tonConnect',
])

const selectedCurrencyId = ref()

function handleCurrencySelected(id: number) {
  selectedCurrencyId.value = id
  goTo('form')
}

const isTonConnectDrawerOpened = ref(false)
const depositAddress = ref('')
function handleTonConnect(address: string) {
  depositAddress.value = address

  if (props.platform === 'desktop') {
    goTo('tonConnect')
  } else {
    isTonConnectDrawerOpened.value = true
  }
}
</script>

<style scoped>
.deposit {
  display: flex;
  flex-direction: column;
  min-height: 657px;
  padding-top: var(--spacing-300);
}

.deposit.mobile {
  width: 100%;
  height: 100%;
  min-height: auto;
  padding-top: 0;
}
</style>
