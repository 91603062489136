<template>
  <div class="deposit-form" :class="type" data-t="deposit-form">
    <div v-if="type === 'desktop'" class="top-controls">
      <div class="go-back">
        <StButton
          :label="t('payments.deposit.goBack')"
          size="l"
          type="text-only"
          icon="chevron-left"
          is-left-icon
          @click="emit('back')"
        />
      </div>
      <StTooltip trigger="hover">
        <template #activator>
          <div class="top-control-action">
            <StIcon name="book-solid" size="16" />
            <div class="text">
              {{ t('payments.deposit.howToReplenish') }}
            </div>
          </div>
        </template>
        <div class="tooltip-content">
          <DepositTooltip />
        </div>
      </StTooltip>
    </div>
    <div class="content">
      <div class="top-section">
        <div v-if="type === 'mobile'" class="go-back" @click="emit('back')">
          <StIcon name="chevron-left" size="20" />
        </div>
        <h2>{{ t('payments.deposit.makeDeposit') }}</h2>
        <div v-if="type === 'mobile'" class="close">
          <StIcon
            name="cross-large"
            size="20"
            data-t="close-button-9ja6"
            @click="close"
          />
        </div>
      </div>
      <div class="content-wrapper">
        <DepositBonusBanner
          v-if="favoriteNewDepositBonus"
          :bonus="favoriteNewDepositBonus"
          :platform="type"
          :selected-currency-code="selectedCurrency?.code"
          :selected-currency-icon="selectedCurrency?.icon"
          type="deposit"
        />
        <CurrencySelect
          v-model="currencyId"
          :platform="type"
          operation-type="deposit"
          :is-disabled="isVerification"
        />
        <NetworkSelect
          v-model="networkId"
          :networks="networks"
          :platform="type"
          operation-type="deposit"
          :is-disabled="isVerification"
        />
        <div class="address">
          <DepositAddress
            :loading="status === 'pending' || !!error"
            :selected-currency="selectedCurrency"
            :selected-network="selectedNetwork"
            :address="address"
            :platform="type"
            :is-verification="isVerification"
            @handle-connect="emit('handleConnect', address as string)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import DepositBonusBanner from '@st/bonuses/components/DepositBonusBanner/DepositBonusBanner.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDepositBonusesStore } from '@st/bonuses/stores/useDepositBonusesStore'
import DepositAddress from '../DepositAddress/DepositAddress.vue'
import DepositTooltip from './parts/DepositTooltip.vue'

import { useCurrenciesStore } from '../../stores/useCurrenciesStore'

const currenciesStore = useCurrenciesStore()
const { currencies } = storeToRefs(currenciesStore)
const { open } = useToast()

const emit = defineEmits<{
  back: []
  handleConnect: [address: string]
}>()

const props = withDefaults(
  defineProps<{
    selectedCurrencyId: number
    type: 'mobile' | 'desktop'
  }>(),
  { type: 'desktop' },
)

const { t } = useI18n()

const router = useRouter()
function close() {
  router.replace({ query: {} })
}

const currencyId = ref(props.selectedCurrencyId)
const selectedCurrency = computed(() => currencies.value[currencyId.value])

const networkId = ref()
const networks = computed(
  () => currencies.value[currencyId.value].networks || [],
)

watch(currencyId, () => {
  if (networks.value.length === 1) {
    networkId.value = networks.value[0].id
  } else {
    networkId.value = ''
  }
})

const selectedNetwork = computed(
  () => networks.value?.find((network) => network.id === networkId.value),
)

const { data, execute, status, error } = useStFetch('/deposit/address/get', {
  method: 'POST',
  immediate: false,
  watch: false,
  body: computed(() => ({
    currencyId: currencyId.value,
    networkId: networkId.value,
  })),
})

watch(networkId, (newValue) => {
  if (!newValue) return

  execute()
})

const isVerification = ref(false)
const io = useSocket()

function handleRedirectToVerification(
  verificationStatus:
    | 'userWait'
    | 'adminWait'
    | 'adminInProgress'
    | 'approved'
    | 'rejected'
    | 'canceled'
    | 'failed',
) {
  if (verificationStatus === 'rejected' || verificationStatus === 'userWait') {
    router.replace({
      query: {
        modal: 'paymentsKyc',
        type: verificationStatus,
      },
    })
  }

  if (verificationStatus === 'approved') {
    isVerification.value = false
    execute()
  }
}

io.on('userVerificationStatus', (verificationStatusData) => {
  handleRedirectToVerification(verificationStatusData.status)
})

watch(
  () => error.value,
  (err) => {
    if (!err) return
    const errorBody = err.data
    if (!errorBody) return

    const { error: errorCode } = errorBody

    if (errorCode === 'USER_VERIFICATION_CREATED') {
      isVerification.value = true

      return
    }

    open({
      label: t('payments.deposit.somethingWentWrong'),
      type: 'negative',
    })
  },
)

onUnmounted(() => {
  io.off('verificationStatus')
})

const address = computed(() => data.value?.address)

const { favoriteNewDepositBonus } = storeToRefs(useDepositBonusesStore())
</script>

<style scoped>
.deposit-form {
  display: flex;
  flex-direction: column;
}

.top-controls {
  display: flex;
  gap: var(--spacing-300);
  align-items: center;
  justify-content: space-between;

  .go-back {
    flex-grow: 1;
  }
}

h2 {
  margin: 0;
  padding: var(--spacing-050) 0;
  font: var(--desktop-text-2xl-semibold);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  margin-top: var(--spacing-250);
}

.top-control-action {
  cursor: pointer;

  position: relative;

  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  color: var(--text-tertiary);

  .text {
    font: var(--desktop-text-sm-semibold);
  }

  &:hover {
    color: var(--palette-light-1000);
  }
}

.deposit-form.mobile {
  overflow: hidden;
  overflow-y: auto;
  padding: 0 var(--spacing-200) var(--spacing-200);

  .content-wrapper {
    margin-top: var(--spacing-100);
  }

  .top-section {
    position: relative;
    display: flex;
    align-items: center;
    height: var(--spacing-600);
  }

  h2 {
    margin: 0;
    padding: var(--spacing-150);
    font: var(--mobile-title-2-semibold);
    text-align: center;
  }

  .go-back {
    line-height: 0;
    border: none;
  }

  .close {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    line-height: 0;
  }
}
</style>
