import { sortBy } from '@st/utils'
import type { IconName } from '@st/ui/components/StIcon/types'
import { iconsMap } from './iconsMap'

interface ExchangeMethod {
  methodName: string
  methodCode: string
  fixedRateId: string
  fixedRate: number
  expiredFixedRateDate: string
  min?: number
  max?: number
}

interface CalypsoParams {
  currencyId: Ref<number>
  networkId: Ref<number>
  fiatCurrencyId: Ref<string | undefined>
}

interface CalypsoReturn {
  fiatCurrenciesList: ComputedRef<
    {
      id: string
      title: string
      icon: IconName
    }[]
  >
  exchangeMethods: ComputedRef<ExchangeMethod[]>
}

export function useCalypso({
  currencyId,
  networkId,
  fiatCurrencyId,
}: CalypsoParams): CalypsoReturn {
  const { data: calypsoGeoData } = useStFetch('/deposit/calypso/geo', {
    method: 'post',
    body: computed(() => ({
      currencyId: currencyId.value,
      networkId: networkId.value,
    })),
    watch: false,
  })

  const calypsoMethodId = computed(() => calypsoGeoData.value?.methodId ?? '')

  const geoList = computed(() => {
    if (!calypsoGeoData.value?.geo) return []

    return sortBy(calypsoGeoData.value?.geo, (item) => [item.geoCode])
  })

  const fiatCurrenciesList = computed(
    () =>
      geoList.value?.map((item) => ({
        id: item.geoId,
        title: String(item.geoCode),
        icon: iconsMap[item.geoCode] ?? '',
      })),
  )

  const { data: ratesData, execute: fetchRatesData } = useStFetch(
    '/deposit/calypso/rate',
    {
      method: 'post',
      body: computed(() => ({
        currencyId: currencyId.value,
        geoId: fiatCurrencyId.value as string,
        methodId: calypsoMethodId.value,
      })),
      immediate: false,
    },
  )

  watch(calypsoMethodId, () => {
    fetchRatesData()
  })

  const exchangeMethods = computed(() => ratesData.value?.method ?? [])

  return {
    fiatCurrenciesList,
    exchangeMethods,
  }
}
