<template>
  <StOption :id="item.address">
    <div
      :data-t="`item-${item.id}`"
      class="option"
      :class="wrapperClasses"
      role="option"
    >
      <div class="options-content">
        <div class="title-section">
          <div v-if="item.name" class="title" data-t="option-title">
            {{ item.name }}
          </div>
          <div
            v-if="platform === 'mobile' && item.label"
            class="label label-top"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="address-section">
          <div class="subtitle" data-t="option-subtitle">
            <span> {{ styledAddress.first }}</span
            >{{ styledAddress.second }}
          </div>
          <div v-if="platform === 'desktop' && item.label" class="label">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
  </StOption>
</template>

<script setup lang="ts">
import StOption from '@st/ui/components/StBaseSelect/parts/StOption.vue'
import type { SelectOption } from '../types'

const props = withDefaults(
  defineProps<{
    item: SelectOption & { address: string }
    isSelected: boolean
    searchValue?: string
  }>(),
  {
    isSelected: false,
    searchValue: '',
  },
)

const { platform } = usePlatform()

const wrapperClasses = computed(() => [`platform-${platform}`])

const styledAddress = computed(() => {
  if (!props.searchValue) return { first: '', second: props.item.address }

  const index = props.item.address.search(props.searchValue)

  if (index === 0) {
    return {
      first: props.item.address.slice(0, props.searchValue.length),
      second: props.item.address.slice(props.searchValue.length),
    }
  }

  return { first: '', second: props.item.address }
})
</script>

<style scoped>
.option {
  position: relative;

  display: flex;
  gap: var(--spacing-125, 4px);
  align-items: center;

  width: 100%;
  max-height: 48px;
  padding: var(--spacing-150) 0 var(--spacing-150) var(--spacing-150);

  &:hover {
    background-color: var(--background-tertiary);
    border-radius: var(--border-radius-075);
  }

  &.disabled {
    opacity: var(--disable-layer-opasity, 0.4);
  }
}

.options-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.title-section {
  display: flex;
  align-items: center;

  .title {
    font: var(--desktop-text-md-semibold);
  }
}

.subtitle {
  font: var(--desktop-text-xs-medium);
  color: var(--palette-light-600);

  span {
    color: var(--text-primary);
  }
}

.address-section {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.label {
  padding: var(--spacing-025) var(--spacing-050);

  font: var(--desktop-text-xxs-medium);
  color: var(--text-success);

  background-color: rgba(83, 198, 107, 0.12); /* stylelint-disable-line */
  border-radius: var(--border-radius-full);
}

.label-top {
  width: fit-content;
  padding: var(--spacing-025) var(--spacing-050);
}

.caption {
  font: var(--desktop-text-xs-medium);
  color: var(--palette-light-600);
}

.amount {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
}

.top {
  font: var(--desktop-text-md-semibold);
}

.bottom {
  font: var(--desktop-text-xs-medium);
  color: var(--palette-light-600);
}

.option-icon {
  display: flex;
  justify-content: flex-end;

  min-width: var(--spacing-400);
  padding: 0 var(--spacing-100);

  text-align: center;
}

.selected {
  color: var(--system-success);
}

.option.platform-mobile {
  gap: var(--spacing-050);
  min-height: var(--spacing-600);
  padding: var(--spacing-100) var(--spacing-100) var(--spacing-100)
    var(--spacing-150);

  &::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: var(--spacing-150);

    width: calc(100% - var(--spacing-300));

    border-bottom: 1px solid var(--border-primary);
  }

  .options-content {
    gap: var(--spacing-025);
  }

  .title-section {
    display: flex;
    gap: var(--spacing-075);
  }

  .option-icon {
    padding: 0 var(--spacing-050);
  }

  .title {
    font: var(--mobile-text-medium);
  }

  .subtitle {
    font: var(--mobile-text-medium);
  }

  .top {
    margin-bottom: var(--spacing-025);
    font: var(--mobile-text-medium);
  }

  .bottom {
    font: var(--mobile-caption-1-regular);
  }
}
</style>
