import type { IconName } from '@st/ui/components/StIcon/types'

export const iconsMap: Record<string, IconName> = {
  PEN: 'f-per',
  BRL: 'f-bra',
  COP: 'f-col',
  PHP: 'f-phl',
  ZAR: 'f-zaf',
  KRW: 'f-kor',
  CLP: 'f-chl',
  MXN: 'f-mex',
  HKD: 'f-hkg',
  GHS: 'f-gha',
  THB: 'f-tha',
  NGN: 'f-nga',
  AZN: 'f-aze',
  KES: 'f-ken',
  NZD: 'f-nzl',
  MYR: 'f-mys',
  USD_ECU: 'f-ecu',
  VES: 'f-ven',
  IDR: 'f-idn',
  SGD: 'f-sgp',
  EUR: 'f-eu',
  AWG: 'f-abw',
  AFN: 'f-afg',
  AOA: 'f-ago',
  XCD: 'f-aia',
  ALL: 'f-alb',
  AED: 'f-are',
  ARS: 'f-arg',
  AMD: 'f-arm',
  AUD: 'f-aus',
  BIF: 'f-bdi',
  XOF: 'f-ben',
  BDT: 'f-bgd',
  BGN: 'f-bgr',
  BHD: 'f-bhr',
  BSD: 'f-bhs',
  BAM: 'f-bih',
  BYR: 'f-blr',
  BZD: 'f-blz',
  BMD: 'f-bmu',
  BOB: 'f-bol',
  BBD: 'f-brb',
  BND: 'f-brn',
  BTN: 'f-btn',
  BWP: 'f-bwa',
  XAF: 'f-caf',
  CHE: 'f-che',
  CNY: 'f-chn',
  CDF: 'f-cod',
  KMF: 'f-com',
  CVE: 'f-cpv',
  CRC: 'f-cri',
  CUP: 'f-cub',
  ANG: 'f-cuw',
  KYD: 'f-cym',
  CZK: 'f-cze',
  DJF: 'f-dji',
  DKK: 'f-dnk',
  DOP: 'f-dom',
  DZD: 'f-dza',
  EGP: 'f-egy',
  ERN: 'f-eri',
  ETB: 'f-eth',
  FJD: 'f-fji',
  FKP: 'f-flk',
  GBP: 'f-gbr',
  GEL: 'f-geo',
  GIP: 'f-gib',
  GNF: 'f-gin',
  GMD: 'f-gmb',
  GTQ: 'f-gtm',
  GYD: 'f-guy',
  HNL: 'f-hnd',
  HRK: 'f-hrv',
  HTG: 'f-hti',
  HUF: 'f-hun',
  INR: 'f-ind',
  IRR: 'f-irn',
  IQD: 'f-irq',
  ISK: 'f-isl',
  ILS: 'f-isr',
  JMD: 'f-jam',
  JPB: 'f-jey',
  JOD: 'f-jor',
  JPY: 'f-jpn',
  KZT: 'f-kaz',
  KGS: 'f-kgz',
  KHM: 'f-khm',
  KWD: 'f-kwt',
  LAK: 'f-lao',
  LBP: 'f-lbn',
  LRD: 'f-lbr',
  LYD: 'f-lby',
  CHF: 'f-lie',
  LKR: 'f-lka',
  LSL: 'f-lso',
  MOP: 'f-mac',
  MAD: 'f-mar',
  MDL: 'f-mda',
  MGA: 'f-mdg',
  MVR: 'f-mdv',
  MKD: 'f-mkd',
  MMK: 'f-mmr',
  MNT: 'f-mng',
  MZN: 'f-moz',
  MRU: 'f-mrt',
  MUR: 'f-mus',
  MWK: 'f-mwi',
  NAD: 'f-nam',
  NIO: 'f-nic',
  NOK: 'f-nor',
  NRP: 'f-npl',
  OMR: 'f-omn',
  PKR: 'f-pak',
  PAB: 'f-pan',
  PGK: 'f-png',
  PLN: 'f-pol',
  KPW: 'f-prk',
  PYG: 'f-pry',
  XPF: 'f-pyf',
  QAR: 'f-qat',
  RON: 'f-rou',
  RUB: 'f-rus',
  RWF: 'f-rwa',
  SAR: 'f-sau',
  SDG: 'f-sdn',
  SBD: 'f-slb',
  SLL: 'f-sle',
  SVC: 'f-slv',
  SOS: 'f-som',
  RSD: 'f-srb',
  SSP: 'f-ssd',
  STN: 'f-stp',
  SRD: 'f-sur',
  SEK: 'f-swe',
  SZL: 'f-swz',
  SCR: 'f-syc',
  SYP: 'f-syr',
  TJS: 'f-tjk',
  TMT: 'f-tkm',
  TOP: 'f-ton',
  TTD: 'f-tto',
  TND: 'f-tun',
  TRY: 'f-tur',
  TWD: 'f-twn',
  TZS: 'f-tza',
  UGX: 'f-uga',
  UAH: 'f-ukr',
  UYI: 'f-ury',
  USD: 'f-usa',
  UZS: 'f-uzb',
  VND: 'f-vnm',
  VUV: 'f-vut',
  WST: 'f-wsm',
  YER: 'f-yem',
  ZMW: 'f-zmb',
  ZWL: 'f-zwe',
}
