<template>
  <div class="ton-connect">
    <div class="separator">
      <div class="line"></div>
      <div class="text">{{ t('payments.tonConnect.or') }}</div>
      <div class="line"></div>
    </div>
    <StButton
      :label="t('payments.tonConnect.buttonTitle')"
      icon="c-crypto-icon-ton"
      is-left-icon
      type="gray"
      @click="connect"
    />
  </div>
</template>

<script setup lang="ts">
import { useTonConnect } from '../../../composables/useTonConnect'

const emit = defineEmits<{
  handleConnect: []
}>()

const { t } = useI18n()
const { openModal, isConnected } = useTonConnect()

function connect() {
  if (!isConnected.value) {
    openModal()
  } else {
    emit('handleConnect')
  }
}
</script>

<style scoped>
.ton-connect {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.separator {
  display: flex;
  align-items: center;
  padding: var(--spacing-100) 0;

  .text {
    padding: 0 var(--spacing-250);
  }

  .line {
    flex-grow: 1;
    border-top: var(--border-width-light) solid var(--border-primary);
  }
}
</style>
