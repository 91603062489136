<template>
  <div class="content-top" :class="platform">
    <h2 v-if="platform === 'desktop'">
      {{ t('payments.buyCrypto.title') }}
    </h2>
    <div class="top-info">
      <div class="info-block">
        <div class="image">
          <img src="../assets/balance.png" width="30" />
        </div>
        <div class="text">
          {{
            platform === 'desktop'
              ? t('payments.buyCrypto.topInfo1')
              : t('payments.buyCrypto.mTopInfo1')
          }}
        </div>
      </div>
      <div class="info-block">
        <div class="image">
          <img src="../assets/fee.png" width="30" />
        </div>
        <div class="text">{{ t('payments.buyCrypto.topInfo2') }}</div>
      </div>
      <div class="info-block">
        <div class="image">
          <img src="../assets/popular.png" width="30" />
        </div>
        <div class="text">
          {{ t('payments.buyCrypto.topInfo3') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { platform } = usePlatform()

const { t } = useI18n()
</script>

<style scoped>
.content-top {
  display: flex;
  flex-direction: column;
}

h2 {
  margin: var(--spacing-100) 0 0;
  font: var(--desktop-text-2xl-semibold);
}

.top-info {
  display: flex;
  gap: var(--spacing-300);
  margin-top: var(--spacing-200);
}

.info-block {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;

  .text {
    font: var(--desktop-text-sm-medium);
    color: var(--text-secondary);
  }

  .image {
    height: 28px;
  }
}

.content-top.mobile {
  .top-info {
    gap: var(--spacing-125);
    margin-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .info-block {
    .text {
      font: var(--mobile-caption-1-regular);
    }
  }
}
</style>
