<template>
  <form class="first-step" :class="platform" @submit.prevent="handleSubmit">
    <NavigationTabs :type="platform" />
    <div v-if="isVerification" class="prepare-verification">
      <StSpinner class="spinner" size="32" />
      <div class="text-content">
        <h2>{{ t('payments.deposit.verificationTitle') }}</h2>
        <span>{{ t('payments.deposit.verificationSubtitle') }}</span>
      </div>
    </div>
    <div v-else class="content">
      <ContentTop />
      <div class="content-wrapper">
        <DepositBonusBanner
          v-if="favoriteNewDepositBonus"
          :bonus="favoriteNewDepositBonus"
          :platform="platform"
          :selected-currency-code="selectedCurrency?.code"
          :selected-currency-icon="selectedCurrency?.icon"
          type="buy"
        />
        <Inputs
          v-model:crypto-amount="cryptoAmount"
          v-model:fiat-amount="fiatAmount"
          v-model:crypto-amount-error="cryptoAmountError"
          v-model:fiat-amount-error="fiatAmountError"
        />
      </div>
      <PaymentMethods :is-disabled="!fiatAmount" :amount="fiatAmount" />
      <div class="disclaimer">
        {{ t('payments.buyCrypto.disclaimer') }}
      </div>
      <div class="button-wrapper">
        <StButton
          :size="platform === 'desktop' ? 'xl' : 'l'"
          :label="
            cryptoAmount
              ? t('payments.buyCrypto.buy2', { amount: recieveAmount })
              : t('payments.buyCrypto.buy')
          "
          class="buy-button"
          :loading="isLoading"
          :disabled="isDisabledButton"
          submit
        />
        <div class="buy-crypto-tooltip-wrapper">
          <BuyCryptoTooltip />
        </div>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import DepositBonusBanner from '@st/bonuses/components/DepositBonusBanner/DepositBonusBanner.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDepositBonusesStore } from '@st/bonuses/stores/useDepositBonusesStore'
import Decimal from '@st/decimal'
import { isValidNumber } from '@st/utils'
import PaymentMethods from './PaymentMethods.vue'
import BuyCryptoTooltip from '../../BuyCryptoTooltip/BuyCryptoTooltip.vue'
// import FastAmounts from './FastAmounts.vue'
import { useDataStore } from './useData'
import ContentTop from './ContentTop.vue'
import Inputs from './Inputs.vue'
import { useLimits } from './useLimits'

const emit = defineEmits<{
  (e: 'goToNextStep', value: { frameUrl: string }): void
}>()

const { platform } = usePlatform()

const { t } = useI18n()
const stFetch = useRawStFetch()

const dataStore = useDataStore()
const {
  exchangeMethods,
  selectedMethod,
  currencyId,
  networkId,
  selectedCurrency,
  selectedFiatCurrency,
} = storeToRefs(dataStore)

const { format: formatCrypto } = useCryptoFormatter({
  currency: computed(() => selectedCurrency.value.code),
})

const cryptoAmount = ref('')
const fiatAmount = ref('')

const selectedMethodName = computed(() => {
  const foundMethod = exchangeMethods.value.find(
    (method) => method.fixedRateId === selectedMethod.value?.fixedRateId,
  )
  if (!foundMethod) return ''

  return foundMethod.methodName
})

const isLoading = ref(false)
const fiatAmountError = ref('')
const cryptoAmountError = ref('')

const isDisabledButton = computed(() => {
  if (!isValidNumber(cryptoAmount.value)) return true

  if (fiatAmountError.value || cryptoAmountError.value) return true

  return !cryptoAmount.value
})

const toast = useToast()
const isVerification = ref(false)

const recieveAmount = computed(() => {
  if (!isValidNumber(cryptoAmount.value)) return ''

  return formatCrypto(cryptoAmount.value)
})

const limits = useLimits({
  selectedMethod,
  currencyId,
})

function validate(): boolean {
  if (
    !cryptoAmount.value ||
    new Decimal(cryptoAmount.value.trim()).greaterThan(limits.value.maxLimit)
  ) {
    cryptoAmountError.value = t('payments.buyCrypto.maxAmountError', {
      maxAmount: formatCrypto(limits.value.maxLimit),
    })

    return false
  }

  if (new Decimal(cryptoAmount.value.trim()).lessThan(limits.value.minLimit)) {
    cryptoAmountError.value = t('payments.buyCrypto.minAmountError', {
      minAmount: formatCrypto(limits.value.minLimit),
    })
    return false
  }

  return true
}

async function handleSubmit() {
  if (!selectedMethod.value) return

  const isValid = validate()
  if (!isValid) return

  isLoading.value = true

  const { data, error } = await stFetch('/deposit/calypso/init', {
    method: 'post',
    body: {
      amount: cryptoAmount.value,
      currencyId: currencyId.value,
      networkId: networkId.value,
      rateId: selectedMethod.value.fixedRateId,
      fiatCurrency: selectedFiatCurrency.value?.title ?? '',
      fiatAmount: fiatAmount.value,
      fiatMethod: selectedMethodName.value,
    },
  })
  isLoading.value = false

  if (error) {
    if (error.error === 'USER_VERIFICATION_CREATED') {
      isVerification.value = true

      return
    }

    toast.open({
      label: t('payments.buyCrypto.somethingWentWrong'),
      type: 'negative',
    })
    return
  }

  emit('goToNextStep', { frameUrl: data.calypsoDepositLink })
}

const io = useSocket()
const router = useRouter()

function handleRedirectToVerification(
  verificationStatus:
    | 'userWait'
    | 'adminWait'
    | 'adminInProgress'
    | 'approved'
    | 'rejected'
    | 'canceled'
    | 'failed',
) {
  if (verificationStatus === 'rejected' || verificationStatus === 'userWait') {
    router.replace({
      query: {
        modal: 'paymentsKyc',
        type: verificationStatus,
      },
    })
  }

  if (verificationStatus === 'approved') {
    isVerification.value = false
    handleSubmit()
  }
}

io.on('userVerificationStatus', (verificationStatusData) => {
  handleRedirectToVerification(verificationStatusData.status)
})

const { favoriteNewDepositBonus } = storeToRefs(useDepositBonusesStore())
</script>

<style scoped>
.first-step {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  height: 100%;
  height: 730px;
  padding-top: var(--spacing-300);
}

.content {
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);
  justify-content: flex-start;

  height: 100%;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  margin: var(--spacing-050) 0 var(--spacing-100);
}

.amount-input-select {
  margin-top: var(--spacing-100);
}

.currency {
  color: var(--text-tertiary);
}

.disclaimer {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;

  margin-top: var(--spacing-100);
  margin-bottom: var(--spacing-050);

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: center;

  p {
    margin: 0;
    padding: 0;
  }

  span {
    color: var(--text-primary);
  }
}

.disclaimer-title {
  color: var(--text-primary);
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.buy-button {
  width: 100%;
}

/* stylelint-disable */
.prepare-verification {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-250);
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  text-align: center;

  .spinner {
    color: var(--icon-primary);
  }

  .text-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-050);
    align-items: center;
  }

  h2 {
    margin: 0;
    padding: 0;
    font: var(--desktop-text-xl-semibold);
  }

  span {
    font: var(--desktop-text-md-medium);
    color: var(--text-tertiary);
  }
}
/* stylelint-enable */

.buy-crypto-tooltip-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacing-300) 0 0;
}

.first-step.mobile {
  height: 100%;
  min-height: auto;
  padding-top: 0;

  h2 {
    margin: 0;
    font: var(--mobile-title-1-semibold);
  }

  .content {
    overflow: hidden;
    overflow-y: auto;
    gap: var(--spacing-150);
    padding: var(--spacing-200) var(--spacing-200) 0;
  }

  .button-wrapper {
    padding: var(--spacing-050) var(--spacing-200) var(--spacing-200);
  }

  .amount-buttons {
    margin-top: calc(-1 * var(--spacing-100));
  }

  .disclaimer {
    margin-bottom: 0;
    padding-bottom: 0;
    font: var(--mobile-caption-1-regular);
  }

  .content-wrapper {
    margin-top: 0;
  }

  .info-block {
    .text {
      font: var(--mobile-caption-1-regular);
    }
  }

  .buy-crypto-tooltip-wrapper {
    margin: var(--spacing-200) 0 0;
  }
}
</style>
